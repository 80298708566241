@font-face {
    font-family: 'FacundoBlack';
    src: url('../public/fonts/Fontspring-DEMO-facundo-black.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

@font-face {
    font-family: 'FacundoRegular';
    src: url('../public/fonts/Fontspring-DEMO-facundo-regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}